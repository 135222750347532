<template>
  <div class="page-main">
    <div class="m-b-10">
      <el-button size="mini" type="success" @click="dataSync">同步权限</el-button>
    </div>
    <div v-for="(item, index) in listData" :key="index">
      <div class="table-title">
        <div style="width: 500px">{{ item.controller_title }}</div>
        <div>
          <el-input
            v-model="item.title"
            placeholder="模块名称"
            size="mini"
            style="width: 290px"
            @blur="itemStUpdate(item)"
          />
        </div>
      </div>

      <!-- 表格 -->
      <vxe-grid
        class="m-b-10"
        size="mini"
        border
        highlight-hover-row
        :min-height="0"
        :show-header="false"
        :mouse-config="{ selected: false }"
        :data="item.children"
        :columns="tableColumn"
      >
        <template #default_remark="{ row }">
          <vxe-input v-model="row.remark" placeholder="功能名称" @blur="itemNdUpdate(row)" />
        </template>
        <template #default_operate="{ row }">
          <template>
            <vxe-button
              class="m-l-5"
              icon="el-icon-delete"
              title="删除"
              @click="itemNdDelete(row)"
            />
          </template>
        </template>
      </vxe-grid>
    </div>
    <div v-if="!loaded" class="page-loading">数据加载中...</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      listData: [],
      tableColumn: [
        { field: 'action_key', width: 500, title: '名称' },
        {
          field: 'remark',
          width: 300,
          title: '备注',
          slots: {
            default: 'default_remark'
          }
        },
        { title: '操作', slots: { default: 'default_operate' } }
      ]
    }
  },
  created() {
    this.getListData()
  },
  methods: {
    // 数据同步
    dataSync() {
      this.$api({
        method: 'post',
        url: '/admin/permission/syncVue'
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success(res.data.msg)
          this.getListData()
        }
      })
    },
    // 获取数据列表
    getListData() {
      this.loaded = false
      this.$api({
        method: 'post',
        url: '/admin/role/list'
      }).then((res) => {
        if (res.status === 200) {
          this.loaded = true
          this.listData = res.data.data
        }
      })
    },
    // 项一级-更新
    itemStUpdate(data) {
      this.$api({
        method: 'post',
        url: '/admin/role/changeControllerName',
        data: {
          title_id: data.title_id,
          title: data.title
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项二级-更新
    itemNdUpdate(row) {
      this.$api({
        method: 'post',
        url: '/admin/permission/updateVue',
        params: {
          id: row.id,
          remark: row.remark
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项二级-删除
    itemNdDelete(row) {
      this.$confirm('您确认要删除该数据?', '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/permission/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success(res.data.msg)
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  .page-loading {
    border: 1px solid #ecf5ff;
    line-height: 100px;
    text-align: center;
  }
  .table-title {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 4px;
    background-color: #ecf5ff;
    border: 1px solid #e4e7ed;
    border-bottom: none;
  }
  /deep/ .vxe-table--render-default.size--mini .vxe-body--column:not(.col--ellipsis) {
    height: 36px;
    padding: 0;
  }
}
</style>
